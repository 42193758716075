import React from "react";
import {
    Link
  } from "react-router-dom";
  

export default class Menu extends React.Component{
    constructor(props)
    {
        super(props);
        
        this.state={
            isOpennedMobileMenu:false,
        }
    }

    expandedMenu=()=>{
        this.setState({
            isOpennedMobileMenu:!this.state.isOpennedMobileMenu,
        });
    }

    expandSubMenu=(evt)=>{
        
        
        if(evt.target.parentElement.classList.contains("show") || evt.target.nextSibling.classList.contains("show")){
            evt.target.nextSibling.classList.remove("show");
            evt.target.parentElement.classList.remove("show");
        } else {
            evt.target.nextSibling.classList.add("show");
            evt.target.parentElement.classList.add("show");
        }
    }


    render(){
        return(
            <div className="container-fluid">
                <div className="row border-top px-xl-5">
                    <div className="col-lg-11">
                        <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                            <Link to="/" className="text-decoration-none d-block d-lg-none">
                                <h1 className="m-0"><span className="text-primary">S</span>ASBORT</h1>
                            </Link>
                            <button type="button" onClick={()=>this.expandedMenu()} className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarCollapse" aria-expanded="false">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={this.state.isOpennedMobileMenu ? "navbar-collapse collapse show": "navbar-collapse collapse"}
                                id="navbarCollapse" style={{textAlign:"end", justifyContent:"end"}}>
                                <div className="mobile-contacts text-left" style={{float:"left"}}>
                                    <div style={{width:200, textAlign: "start"}}>
                                        <div className="d-inline-flex align-items-center">
                                            <i className="fa fa-1x fa-phone text-primary mr-3"></i>
                                            <div className="text-left">
                                                <h6 className="font-weight-semi-bold mb-1">
                                                    <a href="tel:01604389025">01-604-389-025</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width:200, textAlign: "start"}}>
                                        <div className="d-inline-flex align-items-center">
                                            <i className="fa fa-1x fa-envelope text-primary mr-3"></i>
                                            <div className="text-left">
                                                <h6 className="font-weight-semi-bold mb-1">
                                                    <a href="mailto: info@sasbort.co.uk">
                                                        info@sasbort.co.uk
                                                    </a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="navbar-nav py-0">
                                    <Link to="/" className="nav-item nav-link">Home</Link>
                                    <Link to="/aboutus" className="nav-item nav-link active">About Us</Link>
                                    <Link to="/services" className="nav-item nav-link">Services</Link>
                                      
                                    {/* justify-content-between
                                    
                                    <div className={"nav-item dropdown "}>
                                        <a href="#" className="" onClick={(e)=>this.expandSubMenu(e)} data-toggle="dropdown" aria-expanded="false">Our Services</a>
                                        <div className="dropdown-menu rounded-0 m-0">
                                            <a href="blog.html" className="dropdown-item">Blog List</a>
                                            <a href="single.html" className="dropdown-item">Blog Detail</a>
                                        </div>
                                    </div> */}
                                    <Link to="/projects" className="nav-item nav-link">Projects</Link>
                                    <Link to="/contactus" className="nav-item nav-link">Contacts</Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        );
    }
}
