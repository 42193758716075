import './App.css';
import {Header} from './components/Header';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import ServiceDetail from './pages/ServiceDetail';
import Projects from './pages/Projects';
import NotFoundPage from './pages/NotFoundPage';
import Menu from './components/Menu';
//import Carousel from './components/Carousel';
import {
  BrowserRouter,
  Routes,
  //Switch,
  //Route,
} from "react-router-dom";
import { Route, Switch } from "react-router";
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import ContactUs from './pages/ContactUs';
import ProjectDetails from './pages/ProjectDetails';

function App() { 
  return (
    <BrowserRouter className="App">
      <div>
        <Header/>
        <Menu/>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="/aboutus" element={<AboutUs/>}/>
          <Route path="/contactus" element={<ContactUs/>}/>
          <Route path='/services' element={<Services />}/>
          <Route path='/servicedetails' element={<ServiceDetail />}/>
          <Route path='/projects' element={<Projects />}/>
          <Route path='/projectdetails' element={<ProjectDetails />}/>
          <Route element={<NotFoundPage/>}/>
        </Routes>
        <Footer />
        <Privacy />
      </div>
  </BrowserRouter>
  );
}

export default App;
