import React from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import BaseModal from "../modal";


export default class ServiceImages extends React.Component{
 constructor(props){
    super(props);
    this.state={isOpenedModal:false, currentImage:""}
 }
 handleModalOpen=(src)=>{
    //debugger;
    if(!src)
        return ;
    
    this.setState({
        isOpenedModal:true,
        currentImage:src
    })
 }
 handleModalClose=()=>{
    this.setState({isOpenedModal:false})
 }

render(){
    let itemData = this.props.images ?? [];
    return(
        <div>
            <ImageList sx={{ width: "100%", height: "auto" }} variant="woven" cols={4} gap={8}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                    <img 
                        onClick={()=>this.handleModalOpen(item.img)}
                        src={`${item.img}?w=161&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                    />
                    </ImageListItem>
                ))}
            </ImageList>
            <BaseModal isOpenedModal={this.state.isOpenedModal} images={itemData} currentImage={this.state.currentImage} handleModalClose={this.handleModalClose} />
        </div>

    )
}

}

