
export const projectsList = [
    {
        id:1,
        name:"Patrick Ruddy Homes Limited, Bulbourne Yard",
        description:"Bulbourne Yard, Marsworth - 2020 year",
        mainImg:"./img/projects/p1/2.jpg",
        imageList:[
            {
                img:"./img/projects/p1/1.jpg"
            },
            {
                img:"./img/projects/p1/2.jpg"
            },
            {
                img:"./img/projects/p1/3.jpg"
            },
            {
                img:"./img/projects/p1/4.jpg"
            },
            {
                img:"./img/projects/p1/5.jpg"
            },
            {
                img:"./img/projects/p1/6.jpg"
            }
        ]

    },
    {
        id:2,
        name:"BELLWAYS, Milton Keynes",
        description:"BELLWAYS, Wavendon, The Glebe",
        mainImg:"./img/projects/p2/6.jpg",
        imageList:[
            {
                img:"./img/projects/p2/1.jpg"
            },
            {
                img:"./img/projects/p2/2.jpg"
            },
            {
                img:"./img/projects/p2/3.jpg"
            },
            {
                img:"./img/projects/p2/4.jpg"
            },
            {
                img:"./img/projects/p2/5.jpg"
            },
            {
                img:"./img/projects/p2/6.jpg"
            },
            {
                img:"./img/projects/p2/7.jpg"
            }
        ]

    },
    {
        id:3,
        name:"Persimmon Homes, Towcester Grange",
        description:"Towcester Grange, Phase 1",
        mainImg:"./img/projects/p3/14.jpg",
        imageList:[
            {
                img:"./img/projects/p3/1.jpg"
            },
            {
                img:"./img/projects/p3/2.jpg"
            },
            {
                img:"./img/projects/p3/3.jpg"
            },
            {
                img:"./img/projects/p3/4.jpg"
            },
            {
                img:"./img/projects/p3/5.jpg"
            },
            {
                img:"./img/projects/p3/6.jpg"
            },
            {
                img:"./img/projects/p3/8.jpg"
            },
            {
                img:"./img/projects/p3/9.jpg"
            },
            {
                img:"./img/projects/p3/10.jpg"
            },
            {
                img:"./img/projects/p3/11.jpg"
            },
            {
                img:"./img/projects/p3/12.jpg"
            },
            {
                img:"./img/projects/p3/13.jpg"
            },
            {
                img:"./img/projects/p3/14.jpg"
            },
            {
                img:"./img/projects/p3/15.jpg"
            },
            {
                img:"./img/projects/p3/16.jpg"
            },
            {
                img:"./img/projects/p3/17.jpg"
            },
            {
                img:"./img/projects/p3/18.jpg"
            },
            {
                img:"./img/projects/p3/19.jpg"
            },
            {
                img:"./img/projects/p3/20.jpg"
            }
        ]

    },
    {
        id:4,
        name:"Tilia Homes, Buckingham",
        description:"",
        mainImg:"./img/projects/p4/1.jpg",
        imageList:[
            {
                img:"./img/projects/p4/1.jpg"
            },
            {
                img:"./img/projects/p4/2.jpg"
            },
            {
                img:"./img/projects/p4/3.jpg"
            },
            {
                img:"./img/projects/p4/4.jpg"
            },
            {
                img:"./img/projects/p4/5.jpg"
            }
        ]

    }
]