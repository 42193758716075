import React from "react";
import {
    Link
  } from "react-router-dom";
  


export default class Footer extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="container-fluid bg-dark text-white py-5 px-sm-3 px-lg-5" style={{marginTop: "90px"}}>
                <div className="row pt-5">
                    <div className="col-lg-7 col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <h5 className="text-primary text-uppercase mb-4" style={{letterSpacing: "5px"}}>Get In Touch</h5>
                                <p><i className="fa fa-map-marker-alt mr-2"></i><a href="https://goo.gl/maps/3sWicuzHfXCHHuCH8">Northampton, Northamptonshire</a></p>
                                <p><i className="fa fa-phone-alt mr-2"></i>
                                    <a href="tel:01604389025">01-604-389-025</a>
                                </p>
                                <p><i className="fa fa-envelope mr-2"></i>
                                    <a href="mailto: info@sasbort.co.uk">
                                        info@sasbort.co.uk
                                    </a>
                                </p>
                                <div className="d-flex justify-content-start mt-4">
                                    <a className="btn btn-outline-light btn-square mr-2" href="https://www.facebook.com/SASBORT"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-outline-light btn-square mr-2" href="https://www.linkedin.com/company/sasbort-ltd/"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-outline-light btn-square" href="https://www.instagram.com/sasbort_ltd/"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <h5 className="text-primary text-uppercase mb-4" style={{letterSpacing: "5px"}}>Our Pages</h5>
                                <div className="d-flex flex-column justify-content-start">
                                    <Link className="text-white mb-2" to="/"><i className="fa fa-angle-right mr-2"></i>HOME</Link>
                                    <Link className="text-white mb-2" to="/services"><i className="fa fa-angle-right mr-2"></i>SERVICES</Link>
                                    <Link className="text-white mb-2" to="/aboutus"><i className="fa fa-angle-right mr-2"></i>ABOUT US</Link>
                                    <Link className="text-white mb-2" to="/contactus"><i className="fa fa-angle-right mr-2"></i>CONTACT US</Link>
                                    {/* <Link className="text-white" to="/"><i className="fa fa-angle-right mr-2"></i>SEO</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 mb-5">
                        <h5 className="text-primary text-uppercase mb-4" style={{letterSpacing: "5px"}}>Newsletter</h5>
                        {/* <p>description</p> */}
                        <div className="w-100">
                            <div className="input-group">
                                <input type="text" className="form-control border-light" style={{padding: "30px"}} placeholder="Your Email Address" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary px-4">Sign Up</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


