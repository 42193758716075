import React  from "react";
import BreadCrums from '../components/BreadCrums';
import ImgMediaCard from '../components/home/ImgMediaCard';
import {servicesList } from '../services/serviceList';

const styles={
    backgroungImage:{
        width:"100%", 
        height:"100%", 
        position:"absolute", 
        zIndex:-1,
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(./img/backgroundEx1.jpg)",
        backgroundSize: "cover"
    }
}
export default class Services extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <div>
                <BreadCrums title="Services" homeLink="/" homeName="Home" imageSRC="./img/backgroundEx1.jpg" />
                {/* <div style={styles.backgroungImage}></div> */}
                {/* <img src='./img/backgroundEx1.jpg' style={{ width:"100%", height:"100%", position:"absolute", zIndex:-1}} /> */}
                <div className="container-fluid py-5">
                    <div className="container py-5" style={{background:"rgb(238 238 238 / 76%)"}}>
                        <div className="text-center mb-5">
                            <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: "5px"}}>Services</h5>
                            <h1>Our Basic Services</h1>
                        </div>
                        <div className="row">
                            {servicesList.map(elm=>(
                                <ImgMediaCard key={elm.title} className="col-lg-6 col-md-6 mb-4 flex-content-center" props={elm}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}