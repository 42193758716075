import React from 'react';
import ImgMediaCard from '../components/home/ImgMediaCard';
import Carousel from '../components/Carousel';

export default class Home extends React.Component{
    constructor(props){
        super(props)

    }
    
    render(){
        return (
            <div>
                <div className='homeBackgroundImage' style={{ backgroundImage:"url(./img/home/1.jpg)", backgroundRepeat:"no-repeat", backgroundSize:"100%", height:"100%",width:"100%", position:"absolute", zIndex:-1}}></div>
                <div className="container-fluid py-5">
                    <div className="container py-5 mobine-no-padding" >
                        <div className="text-center mb-5 mobine-no-padding">
                            <h2 className="mb-3" style={{letterSpacing: "5px",fontFamily: "cursive"}}>
                                Brickwork specialists
                            </h2>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h1 style={{background:"#ffffff", borderRadius:10, fontFamily: 'Font Awesome 5 Brands',padding: 20, width: "80%"}}>
                                    Top Quality Services Guaranteed
                                </h1>
                            </div>
                            <div style={{minHeight:450}}></div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}