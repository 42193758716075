import React from "react";
import {
    Link
  } from "react-router-dom";
  
export default class BreadCrums extends React.Component{
    render(){
        const imageSRC = this.props.imageSRC ?? "./img/b1.jpg";
        return <div className="container-fluid page-header" style={{ background:"linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + imageSRC + "), no-repeat center center",
        backgroundSize:"cover"}}>
        <div className="container">
            <div className="d-flex flex-column justify-content-center" style={{minHeight: '300px'}}>
                <h3 className="display-4 text-white text-uppercase">{this.props.title}</h3>
                <div className="d-inline-flex text-white">
                    <p className="m-0 text-uppercase"><Link className="text-white" to={this.props.homeLink}>{this.props.homeName}</Link></p>
                    <i className="fa fa-angle-double-right pt-1 px-3"></i>
                    <p className="m-0 text-uppercase">{this.props.title}</p>
                </div>
            </div>
        </div>
    </div>
    }
}