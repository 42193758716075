import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Carousel from 'react-material-ui-carousel';
import { height } from '@mui/system';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "90%",
  height:"50%",
  minWidth:"80%",
  bgcolor: 'background.paper',
  backgroundColor:"transparent",
};

export default class BaseModal extends React.Component {
  constructor(props){
    super(props)
    /////isOpenedModal={this.state.isOpenedModal} handleModalClose
    this.state={open: props.isOpenedModal}
  }

  handleOpen = () => this.setState({open:true});
  handleClose = () => {
    if(this.props.handleModalClose){
      this.props.handleModalClose();
    }
  
    this.setState({open:false})
  };

  render(){
    let imagesList = this.props.images ?? [];
    return (
      <div>
        {/* <Button onClick={this.handleOpen}>Open modal</Button> */}
        <Modal
          open={this.props.isOpenedModal}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className='carousel-image-adaptive'>
            <Carousel
              style={{height:"max-content"}}
              navButtonsAlwaysVisible="true"
            >
              {
                  imagesList.map( (item, i) => <img className='carousel-image-adaptive' key={"elmCarousel_"+i} src={item.img} /> )
              }
            </Carousel>
          </Box>
        </Modal>
      </div>
    );
  }

}
