import React  from "react";
import {
    Link
  } from "react-router-dom";
  


export default class ProjectItem extends React.Component{
    constructor(props){
        super(props);
    }

render(){
    return(
        <div className="col-md-8 col-lg-4 text-center team mb-4 shadow-item">
            <div className="team-item overflow-hidden mb-2">
                <div className="team-img position-relative">
                    <img className="img-fluid" src={this.props.image} alt="" />
                    {/* <div className="team-social">
                        <a className="btn btn-outline-light btn-square mx-1" href="#"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-square mx-1" href="#"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-square mx-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                    </div> */}
                </div>
                <div className="p-4">
                    <h5 style={{textAlign:"initial", textDecoration: "underline"}}>{this.props.name}</h5>
                    <Link to={"/projectdetails?projectId="+this.props.id}>
                        <p className="m-0 view-details">VIEW PROJECT →</p>
                    </Link>
                </div>
            </div>
        </div>
    );
}




}






