import React from "react";
import BreadCrums from "../components/BreadCrums"
///import { SMTPClient } from 'emailjs'
import {emailUser} from '../services/emailService';

export default class ContactUs extends React.Component{
    constructor(props){
        super(props)

        this.state={
            userName:"",
            userNameError:" ",
            userEmail:'',
            userEmailError:" ",
            emailMessage:'',
            emailMessageError:" ",
            subject:'',
            subjectError:'',
        }
    }


    sendEmail=()=>{
        this.setState({errorMessage:null})
        if(!this.state.userEmailError && !this.state.userNameError && !this.state.subjectError && !this.state.emailMessageError ){            
            const mailBody = {
                to:"info@sasbort.co.uk",
                from:this.state.userEmail,
                subject:this.state.subject,
                body:"You receive new contact request from <b>" + this.state.userName + "</b> with <b>" + this.state.userEmail 
                + "</b> email with message:"+this.state.emailMessage
            }

            fetch("https://us-central1-sasbort-ltd-360921.cloudfunctions.net/sendMail?to="+mailBody.to+"&from="+mailBody.from 
            + "&subject=" + mailBody.subject + "&body="+mailBody.body)
            .then(res=>console.log("Response:",res))
            .then(()=>window.location.reload());

        }else{
            this.setState({errorMessage:"Please fill fields with valid values."})
        }
    }

    changeName = (ev)=>{
        if(ev.target.value){
            this.setState({userNameError:null, userName: ev.target.value})
        }else{
            this.setState({userNameError:"Please fill field with your name."})
        }
    }

    changeEmail = (ev)=>{
        var regex = /\S+@\S+\.\S+/;
        if(regex.test(ev.target.value)){
            this.setState({userEmailError:null, userEmail: ev.target.value})
        }else{
            this.setState({userEmailError:"Please fill correct email template. Ex: john.doe@gmail.com"})
        }
    }
    changeSubject = (ev)=>{
        if(ev.target.value){
            this.setState({subjectError:null, subject: ev.target.value})
        }else{
            this.setState({subjectError:"Please fill current field."})
        }
    }
    changeMessage = (ev)=>{
        if(ev.target.value){
            this.setState({emailMessageError:null, emailMessage: ev.target.value})
        }else{
            this.setState({emailMessageError:"Please fill the field with your question or contacts."})
        }
    }

    render(){
        return(
        <div>
            <BreadCrums title="Contact Us" homeLink="/" homeName="Home"/>
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="text-center mb-5">
                        <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: "5px"}}>Contact</h5>
                        <h1>Contact For Any Query</h1>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="contact-form bg-secondary rounded p-5">
                                <div id="success"></div>
                                <div name="sentMessage" id="contactForm" noValidate="novalidate">
                                    <div className="control-group">
                                        <input type="text" onChange={(evt)=>this.changeName(evt)} className="form-control border-0 p-4" id="name" placeholder="Your Name" 
                                        required="required" data-validation-required-message="Please enter your name" />
                                        <p className="help-block text-danger">{this.state.userNameError ? this.state.userNameError : null }</p>
                                    </div>
                                    <div className="control-group">
                                        <input type="email" className="form-control border-0 p-4"  onChange={(evt)=>this.changeEmail(evt)} id="email" placeholder="Your Email" 
                                        required="required" data-validation-required-message="Please enter your email" />
                                        <p className="help-block text-danger">{this.state.userEmailError ? this.state.userEmailError : null }</p>
                                    </div>
                                    <div className="control-group">
                                        <input type="text" className="form-control border-0 p-4" onChange={(evt)=>this.changeSubject(evt)} id="subject" placeholder="Subject" 
                                        required="required" data-validation-required-message="Please enter a subject"/>
                                        <p className="help-block text-danger">{this.state.subjectError ? this.state.subjectError : null }</p>
                                    </div>
                                    <div className="control-group">
                                        <textarea className="form-control border-0 py-3 px-4" rows="5" id="message" onChange={(evt)=>this.changeMessage(evt)} placeholder="Message" required="required" data-validation-required-message="Please enter your message"></textarea>
                                        <p className="help-block text-danger">{this.state.emailMessageError ? this.state.emailMessageError : null}</p>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-primary py-3 px-5" onClick={()=>this.sendEmail()} type="submit" id="sendMessageButton">Send Message</button>
                                    </div>
                                    {
                                        this.state.errorMessage ? 
                                        <div style={{color:"red"}}>
                                            Please fill all fields to continue with email sending.
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}