import React from 'react';


export default class NotFoundPage extends React.Component{
    constructor(props){
        super(props)

    }



    render(){
        return (<div className="container-fluid page-header" style={{marginBottom: "90px"}}>
                    <div className="container">
                        <h1>
                            This information doesn't exist!
                        </h1>
                        <h3>Please try later.</h3>
                    </div>
                </div>);
    }
}