import React  from "react";
export default class ProjectImageDetails extends React.Component{
    constructor(props){
        super(props);
    }

render(){
    return(
        <div className="col-md-8 col-lg-4 text-center team mb-4 shadow-item">
            <div className="team-item overflow-hidden mb-2">
                <div className="team-img position-relative">
                    <img className="img-fluid" src={this.props.image} alt="" />
                </div>
            </div>
        </div>
    );
}




}






