import React from "react";
import BreadCrums from '../components/BreadCrums';
import ProjectItem from "../components/projects/projectItem";
import {projectsList} from '../services/projectsList';



export default class Projects extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
        <div>
            <BreadCrums title="Projects" homeLink="/" homeName="Home" imageSRC="./img/brc-prj.jpg" />
            <div className="container-fluid py-5">
                <div className="container pt-5 pb-3">
                    <div className="text-center mb-5">
                        {/* <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: 5}}>Teachers</h5> */}
                        <h1>Meet Our Projects</h1>
                    </div>
                    <div className="row">
                        {projectsList.map((elm,id)=> <ProjectItem key={"project_item_"+id} image={elm.mainImg} name={elm.name} id={elm.id}/>)}
                    </div>
                </div>
            </div>
        </div>);
    }
}


