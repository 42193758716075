import React  from "react";
import BreadCrums from '../components/BreadCrums';
import ImgMediaCard from '../components/home/ImgMediaCard';
import RightSideInfo from '../components/servicedetails/rightBreadcrums';
import NotFoundPage from "./NotFoundPage";
import ServiceImages from "../components/servicedetails/ImageList";
import BaseModal from "../components/modal";
import {servicesList} from "../services/serviceList"; 


const styles={
    backgroungImage:{
        width:"100%", 
        height:"100%", 
        position:"absolute", 
        zIndex:-1,
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(./img/backgroundEx1.jpg)",
        backgroundSize: "cover"
    }
}

export default class ServiceDetail extends React.Component{
    constructor(props){
        super(props)
    }
    renderDetails=()=>{
        //debugger;
        const query = new URLSearchParams(window.location.search);
        const name = query.get("name");
        const currentService = servicesList.find(elm=>elm.title.includes(name));


        if(!currentService){
            return <NotFoundPage/>
        }

        return  (<div className="container-fluid py-5">   
        <div className="container py-5">
            <div className="row">
                <div className="col-lg-8">
                    <div className="mb-5">
                        {/* <h6 className="text-primary mb-3">Jan 01, 2050</h6> */}
                        <h1 className="mb-5">{currentService.title}</h1>
                        <img className="img-fluid rounded w-100 mb-4" src={currentService.imgSrc} alt="Image" />
                        <p>{currentService.details}</p>
                    </div>
                    <ServiceImages images={currentService.images}/>
                </div>
                <RightSideInfo/>
            </div>
        </div>
    </div>);
    }

    openModal=()=>{

    }

    render(){
        return (
            <div>
                <BreadCrums title="Service Details" homeLink="/services" homeName="Services" imageSRC="./img/backgroundEx1.jpg" />
                {this.renderDetails()}
            </div>

        );
    }
}