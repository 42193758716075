import React from "react";
import { projectsList } from "../../services/projectsList";
import { servicesList } from "../../services/serviceList";
import {
    Link
  } from "react-router-dom";
  


export default class RightSideInfo extends React.Component{
    constructor(props){
        super(props)
     }
     render(){
        return(
            <div className="col-lg-4 mt-5 mt-lg-0">
            {/* <!-- Author Bio --> */}
            <div className="d-flex flex-column text-center bg-dark rounded mb-5 py-5 px-4">
                <img src="./img/house1.jpg" className="img-fluid rounded-circle mx-auto mb-3" style={{width: "100px"}} />
                <h3 className="text-primary mb-3">SASBORT-LTD</h3>
                <h3 className="text-uppercase mb-4" style={{letterSpacing: "5px"}}>Tag Cloud</h3>
                <p className="text-white m-0">Whatever the size of the project, 
                we aspire for finishing all projects in time with professionalism, 
                efficiency and safeness. You can rely on our hardwork!</p>
            </div>
            <div className="mb-5">
                <h3 className="text-uppercase mb-4" style={{letterSpacing: "5px"}}>Services</h3>
                <ul className="list-group list-group-flush">
                    {servicesList.map((elm,id)=>
                    <li key={"service-breadcrums-id-"+id} className="list-group-item d-flex justify-content-between align-items-center px-0">
                        <a className="text-decoration-none h6 m-0" href={"/servicedetails?name="+elm.title}>{elm.title}</a>
                        <span className="badge badge-primary badge-pill">{elm.images.length}</span>
                    </li>)}
                </ul>
            </div>

            {/* <!-- Recent Post --> */}
            <div className="mb-5">
                <h3 className="text-uppercase mb-4" style={{letterSpacing: "5px"}}>Recent Projects</h3>
                {
                    projectsList.map((elm,id)=>
                    <Link key={"project-breadcrums-id-"+id} className="d-flex align-items-center text-decoration-none mb-3" to={"/projectdetails?projectId="+elm.id}>
                        <img height={80} width={80} className="img-fluid rounded" src={elm.mainImg} alt=""/>
                        <div className="pl-3">
                            <h6 className="m-1">{elm.name}</h6>
                            {/* <small>Jan 01, 2050</small> */}
                        </div>
                    </Link>)
                }
            </div>
            <div className="mb-5">
                <h3 className="text-uppercase mb-4" style={{letterSpacing: "5px"}}>Tags</h3>
                <div className="d-flex flex-wrap m-n1">
                    <a href="" className="btn btn-outline-primary m-1">SASBOST</a>
                    <a href="" className="btn btn-outline-primary m-1">sasbort-ltd</a>
                    <a href="" className="btn btn-outline-primary m-1">brickwork</a>
                    <a href="" className="btn btn-outline-primary m-1">Building</a>
                    <a href="" className="btn btn-outline-primary m-1">Consulting</a>
                </div>
            </div>
        </div>
        );
     }


}