import React from 'react';

const styles={
    headerName:{
        fontWeight:'700 !important',
    }
}

export const Header = ()=> {

    return (
        <div className="d-none d-lg-block" style={{backgroundImage:"url(./img/header-white.jpg)",
        backgroundPosition: 'center',
        backgroundSize: 'cover'
        }}>
            <div style={{
                height:"100%",
                width:"100%", 
                backgroundColor:"#ffffff50",
                paddingLeft:"15px",
                paddingRight:"15px",
                }}>
                <div className="row align-items-center py-1 px-xl-5">
                    <img src='./logo.png' className="col-lg-1" alt='logo.png' />
                    <div className="col-lg-2">
                        <a href="/" className="text-decoration-none">
                            <h1 className="m-0 text-complimentary" style={styles.headerName} ><span className="text-primary">S</span>ASBORT</h1>
                        </a>
                    </div>
                    <div className="col-lg-8 text-right" style={{display:"flex", justifyContent:"end"}}>
                        <div className="text-right pr-2">
                            <div className="d-inline-flex align-items-center mt-1">
                                <a className="btn btn-outline-light btn-square mr-2" href="https://www.facebook.com/SASBORT"><i className="fab fa-2x fa-facebook-f"></i></a>
                                <a className="btn btn-outline-light btn-square mr-2" href="https://www.linkedin.com/company/sasbort-ltd/"><i className="fab fa-2x fa-linkedin-in"></i></a>
                                <a className="btn btn-outline-light btn-square" href="https://www.instagram.com/sasbort_ltd/"><i className="fab fa-2x fa-instagram"></i></a>
                            </div>
                        </div>
                        <div className="text-left" style={{display:"grid",justifyContent:"end"}}>
                            <div style={{width:200, textAlign: "start"}}>
                                <div className="d-inline-flex align-items-center">
                                    <i className="fa fa-1x fa-phone text-primary mr-3"></i>
                                    <div className="text-left">
                                        <h6 className="font-weight-semi-bold mb-1">
                                            <a href="tel:01604389025">01-604-389-025</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:200, textAlign: "start"}}>
                                <div className="d-inline-flex align-items-center">
                                    <i className="fa fa-1x fa-envelope text-primary mr-3"></i>
                                    <div className="text-left">
                                        <h6 className="font-weight-semi-bold mb-1">
                                            <a href="mailto: info@sasbort.co.uk">
                                                info@sasbort.co.uk
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}