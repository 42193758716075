import React from 'react';
export default class AboutUsComponent extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded mb-4 mb-lg-0" src={this.props.imgSrc} alt="" />
                        </div>
                        <div className="col-lg-7">
                            <div className="text-left mb-4">
                                <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>
                                    {this.props.title}
                                </h5>
                                {this.props.subTitle ? <h1>{this.props.subTitle}</h1> : null }
                            </div>
                            <p>
                                {this.props.description}
                            </p>
                            {
                                this.props.detailsLink
                                ? <a href="" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">Learn More</a>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>);
    }
}