import React from 'react';
import AboutUsComponent from '../components/aboutus/Description';
import BreadCrums from '../components/BreadCrums';

const objectDetails = [
    {
        title:"About us",
        description:`The Sasbort Ltd brickwork construction company was formed in 2017. Over the years, the company has undertaken many challenging projects and accumulated skills, know-how and experiences in build solutions plus a good reputation and relationship with our clients that became friends over the years.
        Our professional team at Sasbort Ltd have experience in the brickwork industry working with commercial, residential construction types and restoration brickwork. We offer a wide variety of brickwork and stonework across East Midlands, West Midlands, East of England, South East.`,
        imgSrc:"./img/b1.jpg"
        
    },
    {
        title:"Work experience",
        description:`We can be very proud in a successful collaboration with main contractors such as: Persimmon, Barrat Homes, Ruddy Patrick Home, Morris Homes and other projects.
        We take on all types of jobs from big projects or small one, all carried out to the highest standards as customer satisfaction is particularly important to us.`,
        imgSrc:"./img/work-experience.jpg"

    },
    {
        title:"Safety first",
        description:`Here at Sasbort Ltd were dedicated to construction safety, we follow all site safety operating procedures and regulations to ensure your project remain compliant.
        All our bricklayers and labourers are fully trained with rules of RAMS, Health and Safety and highly-skilled.
        We are insured and we are working under NHBC regulations.`,
        imgSrc:"./img/safetyFirst.jpg"

    },
]



export default class AboutUs extends React.Component{
    constructor(props){
        super(props)

    }

    renderContent=()=>{
        return objectDetails.map((elm)=>
        <AboutUsComponent
            title={elm.title}
            description={elm.description}
            imgSrc={elm.imgSrc}
        />);
    }
    render(){
        return (
        <div>
            <BreadCrums title="About" homeLink="/" homeName="Home"/>
            {this.renderContent()}
        </div>
        );
    }
}