import React from "react";
import BreadCrums from '../components/BreadCrums';
import ProjectItem from "../components/projects/projectItem";
import {projectsList} from '../services/projectsList';
import NotFoundPage from "./NotFoundPage";
import ProjectImageDetails from '../components/projects/projectImageDetails';

export default class ProjectDetails extends React.Component{
    constructor(props){
        super(props)
    }
    renderDetails=()=>{
        //debugger;
        const query = new URLSearchParams(window.location.search);
        const id = query.get("projectId");
        const currentService = projectsList.find(elm=>elm.id ==id);
        if(!currentService){
            return <NotFoundPage/>
        }
        return  (<div className="container-fluid py-5">
                    <div className="container pt-5 pb-3">
                        <div className="text-center mb-5">
                            <h1>{currentService.name}</h1>
                        </div>
                        <div className="row">
                            {currentService.imageList.map((elm,id)=> <ProjectImageDetails key={"projectImagedesc_"+id} image={elm.img} />)}
                        </div>
                    </div>
                </div>);
    }



    render(){
        return (
        <div>
            <BreadCrums title="Project Details" homeLink="/projects" homeName="Projects" imageSRC="./img/brc-prj.jpg" />
            {this.renderDetails()}
        </div>);
    }
}


