import React from "react";  



export default class Carousel extends React.Component{
    constructor(props){
        super(props)
        this.state={
            sliders:[
                {   
                    header:"Best building company",
                    title:"Best service ever, contact..",
                    imageResource:"./img/house1.jpg",
                    isActive:true,
                },
                {
                    header:"Best building company",
                    title:"Be our client and get...",
                    imageResource:"./img/house2.jpg",
                    isActive:false,
                },
                {
                    header:"Best building company",
                    title:"High quality, guarantee, best specialists",
                    imageResource:"./img/house3.jpg",
                    isActive:false,
                },
            ],
            sliderIterator:0,
        }
    }

    renderDots=()=>{
        return this.state.sliders.map(((elm,index)=>
            <li key={"dots" + index} data-target="#header-carousel" onClick={()=>this.setClickedDot(index)} data-slide-to={index} className={elm.isActive?"active":""}></li>))
    }

    renderSliders = ()=>{
    return this.state.sliders.map((elm, index)=> <div key={index+"slider"} className={"carousel-item" + (elm.isActive ? "active":"")} style={{minHeight: '300px'}}>
    <img className="position-relative w-100" src={elm.imageResource} style={{minHeight: '300px', objectFit: 'cover'}}/>
    <div className="carousel-caption d-flex align-items-center justify-content-center">
        <div className="p-5" style={{width: '100%', maxWidth: '900px'}} >
            <h5 className="text-white text-uppercase mb-md-3">{elm.header}</h5>
            <h1 className="display-3 text-white mb-md-4">{elm.title}</h1>
            <a href="" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">See details</a>
        </div>
    </div>
</div>
    
    
    )    
    
    }

    componentDidMount(){
        this.startImageSliding();
    }

    setClickedDot(id){

        var listSliders = this.state.sliders;
        listSliders.forEach((element,index) => {
            if(index===id){
                element.isActive=true;
            }else{
                element.isActive=false;
            }
        });
        this.setState({sliders:listSliders, sliderIterator:id})
    }

    startImageSliding(){
        setInterval(() => {
            
            var listSliders = this.state.sliders;
            listSliders.forEach((element,index) => {
                if(index===(this.state.sliderIterator % 3)){
                    element.isActive=true;
                }else{
                    element.isActive=false;
                }
            });
            this.setState({sliders:listSliders, sliderIterator:this.state.sliderIterator+1})
        }, 10000);
    }

    render(){
        return <div className="container-fluid p-0 pb-5 mb-5">
        <div id="header-carousel" className="carousel slide carousel-fade" data-ride="carousel">
            <ol className="carousel-indicators">
                {this.renderDots()}
            </ol>
            <div className="carousel-inner">
                {this.renderSliders()}
            </div>
        </div>
    </div>
    }

}




