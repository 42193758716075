export const servicesList =[
    {
        title:"Brickwork,blockwork & stonework",
        imgSrc:"./img/brikWorkStoneWotk.jpg",
        details:`Sasbort Ltd have considerable experience in brickwork, blockwork and stonework and are capable of undertaking every aspect of the crafts at highest standartds. 
        We can also efficiently carry out all the blockwork and stonework services to meet the quality of the project. 
        `,
        images:[
            {
                img:"./img/brickwork-blokwork/1.jpg"
            },
            {
                img:"./img/brickwork-blokwork/2.jpg"
            },
            {
                img:"./img/brickwork-blokwork/3.jpg"
            },
            {
                img:"./img/brickwork-blokwork/4.jpg"
            },
            {
                img:"./img/brickwork-blokwork/5.jpg"
            },
            {
                img:"./img/brickwork-blokwork/6.jpg"
            },
            {
                img:"./img/brickwork-blokwork/7.jpg"
            },
            {
                img:"./img/brickwork-blokwork/8.jpg"
            },
            {
                img:"./img/brickwork-blokwork/9.jpg"
            },
            {
                img:"./img/brickwork-blokwork/10.jpg"
            },
            {
                img:"./img/brickwork-blokwork/11.jpg"
            },
            {
                img:"./img/brickwork-blokwork/12.jpg"
            },
            {
                img:"./img/brickwork-blokwork/13.jpg"
            },
            {
                img:"./img/brickwork-blokwork/14.jpg"
            },
            {
                img:"./img/brickwork-blokwork/15.jpg"
            },
            {
                img:"./img/brickwork-blokwork/16.jpg"
            },
            {
                img:"./img/brickwork-blokwork/18.jpg"
            }
        ]
    },
    {
        title:`New builds residential & commercial`,
        imgSrc:"./img/new_builds.jpg",
        details:`Our team have been working with residential and commercial clients over the years and we know how important and what it is involved with new build projects and work hard to ensure that all your requirements are met. 
        Our employees are following all site procedures and regulations to ensure your project remain compliant.
        `,
        images:[
            {
                img:"./img/new-builds/1.jpg"
            },            
            {
                img:"./img/new-builds/2.jpg"
            },            
            {
                img:"./img/new-builds/3.jpg"
            },            
            {
                img:"./img/new-builds/4.jpg"
            },            
            {
                img:"./img/new-builds/5.jpg"
            },            
            {
                img:"./img/new-builds/6.jpg"
            },            
            {
                img:"./img/new-builds/7.jpg"
            },            
            {
                img:"./img/new-builds/8.jpg"
            },            
            {
                img:"./img/new-builds/9.jpg"
            }
        ]
    },
    {
        title:"Restoration listed buildings",
        imgSrc:"./img/restoration.jpg",
        details:`Restoration is a process of returning a building to its former state. 
        Our main purpose is to restore the historic buildings using traditional and modern methods.
        `,
        images:[
            {
                img:"./img/restoration/1.jpg"
            },
            {
                img:"./img/restoration/2.jpg"
            },
            {
                img:"./img/restoration/3.jpg"
            },
            {
                img:"./img/restoration/4.jpg"
            },
            {
                img:"./img/restoration/5.jpg"
            },
            {
                img:"./img/restoration/6.jpg"
            },
            {
                img:"./img/restoration/7.jpg"
            },
            {
                img:"./img/restoration/8.jpg"
            },
            {
                img:"./img/restoration/9.jpg"
            },
            {
                img:"./img/restoration/10.jpg"
            },
            {
                img:"./img/restoration/11.jpg"
            },
            {
                img:"./img/restoration/12.jpg"
            },
        ]
    },
    {
        title:"Particular jobs",
        imgSrc:"./img/particular-jobs.jpg",
        details:`Our team undertake repair works and new build works on domestic properties such as: repointing, new brick or stone fence, extensions, garages.
        We listen carefully to your requirements and discuss the best way to achieve your goal.
        `,
        images:[
            {
                img:"./img/particular-jobs/1.jpg"
            },
            {
                img:"./img/particular-jobs/2.jpg"
            },
            {
                img:"./img/particular-jobs/3.jpg"
            },
            {
                img:"./img/particular-jobs/4.jpg"
            },
            {
                img:"./img/particular-jobs/5.jpg"
            },
            {
                img:"./img/particular-jobs/6.jpg"
            }
        ]
    },

]